//Overrides and fixes
body {
  margin-left: 0; //Required for correct margin
}


//BUTTONS
.btn {
  margin-left: 0;
}

//NAV
.navbar {
  padding-right: 10px;
}

.navbar-default  .navbar-brand {
  /*background-image: url('./img/logo-icon.png');
  background-size: 22px;
  background-position: 6px 13px;
  */
  padding-left: 15px;
}

.navbar-toggle {
  border: 0 !important;
  box-shadow: none !important;
  margin-right: -5px;
}

.user-nav {
  .btn-link, .btn-link:hover, .btn-link:focus {
    color: #fff;
    padding-top: 15px;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    .dropdown-menu a {
      color: #fff !important;
    }
  }
}


//TABLES
//Remove border on table column headers
thead.no-border td {
  border-left: 0;
}


//FORMS
//Keep consistent width on form input icons
.has-submit .ng-invalid {
  border: 1px solid red;
}

.show-errors .ng-invalid {
  border: 1px solid red;
}

.input-group-addon i {
  width: 18px;
}

label {
  &.required:after {
    content: ' *';
    color: red;
    opacity: .4;
  }
}


//CLEANZONE TIMELINE
ul.timeline li .fa.orange{background:#F17A55;}

//PAGE LAYOUT
.page-head-small {
  h3 {
    margin: 0;
  }
}


//MODALS
.modal-content {
  border-radius: 0;
}

.colored-header .modal-header {
  padding: 20px 20px;

  h4 {
    font-weight: 300;
    margin: 0;
  }
}

.modal-backdrop {
  bottom: 0;
  z-index: 1040;
}


//HELPERS
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


//PAGES
//Login, signup etc.
.logged-out-page {
  .brand {
    display: inline;
    padding-left: 34px;
    text-align: center;

    background: url(./img/logo-icon.png) no-repeat 0 50%;
    background-size: 25px;
  }
}


//RESPONSIVE
//Only using some elements from cleanzone/responsive.scss
@media (max-width: 767px) {
  .cl-mcont{ padding: 15px;}

  .login-container .middle-login{
    width:100%;
    margin-left:0px;
    padding: 0 15px;
    left:auto;
  }
}
