.card {
  margin-bottom: 40px;
  background: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  overflow: hidden;
}

.card__content {
  padding: 20px;
}
