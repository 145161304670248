form {
  .error {
    border: 1px solid red;
  }

  .input-group {
    .input-group-addon {
      background-color: #fafafa;
    }

    .form-control {
      padding: 10px 8px;
      height: auto;
    }
  }

  .btn-disabled {
    opacity: .5;
  }
}