.widget-block.calendar-box{
overflow:hidden;
}

.widget-block.calendar-box h2{
  font-size:28px;
}
.widget-block.calendar-box .day{
  text-align:center;
  font-size:102px;
}

.widget-block.weather-box{
overflow:hidden;

  color:#FFF;
}

.widget-block.weather-box .photo{
  // background: url("../images/sunset2.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height:260px;
}

.widget-block.weather-box h2{
  margin-top:60px;
  font-size:28px;
  font-weight:400;
  text-shadow:1px 2px 2px rgba(0, 0, 0, 0.5);
 }

.widget-block.weather-box .photo{
}

.widget-block.weather-box .day{
  text-align:center;
  font-size:24px;
  font-weight:300;
  text-shadow:1px 2px 2px rgba(0, 0, 0, 0.5);
  margin-bottom:70px;
  margin-top:3px;
}

.fb-box{
  margin-top:0 !important;
	background-image:-moz-linear-gradient(center top , #3E80BF 50%, #325fa0 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#3E80BF), to(#325fa0)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #3E80BF, #325fa0); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #3E80BF, #325fa0); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #3E80BF 50%, #325fa0 102%);
	background-color:#366eab;
	color:#FFFFFF;
	box-shadow:1px 1px 2px rgba(0, 0, 0, 0.35) !important;
}

.fb-box h2{ font-size: 19px; font-weight:400;margin:0 0 5px 0;padding-top:10px;}
.fb-box h3{ font-size: 13px; font-weight:200;margin:0 0 20px 0;}
.fb-box h4{ font-size: 13px; font-weight:400;margin:0 0 5px 0;}
.fb-box img{ border-radius:50%; -webkit-border-radius:50%; border: 3px solid #FFFFFF; margin-bottom:15px;margin-top:20px;}
.fb-box .content{padding:20px;	text-align:center;}
.fb-box .info{background:#264A7E;color:#FFF;border-top:1px solid #426ca5;text-align:center;}
.fb-box .info button{ box-shadow: none;max-width:195px; width:80%;padding:13px;background:#4884c0;border:0;margin:5px 0;text-transform:uppercase;}
.fb-box .info button i{margin-right: 3px}
.fb-box .info div{padding:15px 10px;}
.fb-box .row{margin:0;}


.block .cl, .widget-block .cl{margin-bottom:20px;}

.widget-block{
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.21);
	border:0;
	margin-bottom:30px;
	margin-top:0;
}

.widget-block .row{
	margin:0;
}

.widget-block .padding{padding: 20px 20px;}
.widget-block .vpadding{padding: 20px 0;}
.widget-block .hpadding{padding: 0 20px;}

.block .header, .widget-block .header, .block-flat .header{
	padding:10px;
	background-color:#FEFEFE;
	border-bottom:1px solid #dadada;
}
.block .header{border-radius:3px 3px 0 0;-webkit-border-radius:3px 3px 0 0;padding:10px;}
.block-flat .header{background:transparent;padding:3px 0;}
.block-flat .header.no-border{border:0;}
.block-flat .header h3{
	margin-top:3px;
	font-family: "Raleway",Helvetica,sans-serif;
    font-weight: 200;
}
.block-flat .content{padding:15px 3px 0 3px;}
.block-flat .content.full-width{padding:0; margin-left:-20px;margin-right:-20px;margin-bottom:-20px;}
.block-flat .content h4{margin-top:20px;  font-size: 16px;}
.block-flat .content .title{margin:5px 0 15px 0;}
.block-flat.no-padding .content{
	padding: 0 !important;
}
.legend-container table{width:auto;margin:20px 0 0 20px;}
.legend-container table tr{background:transparent;}
.legend-container table td{border:0;padding:0 5px 3px 5px;}
.legend-container .legendColorBox > div{border:0 !important;}
.legend-container .legendColorBox > div > div{border-width:6px !important;}

.block-flat.no-padding .content table td, .block-flat.no-padding .content table th{padding:11px 10px 7px 10px;}

.block .no-border{border-bottom:0;}

.block .content h2, .widget-block .content h2{
	font-family: 'Open Sans',sans-serif;
}

.block .header h2, .widget-block .header h2, .block-flat .header h2{
	margin:0;
	font-family: "Raleway",Helvetica,sans-serif;
    font-weight: 100;
	font-size:29px;
	 line-height: 38px;
}

.block .header h3, .widget-block .header h3{
	margin:0;
	font-size:13px;
	font-weight:200;
	line-height:21px;
	color: #666;
}

.block .header .actions i, .widget-block .header .actions i{
	font-size:18px;
	color:#6F6F6F;
	display: inline-block;
    padding: 0;
    text-align: center;
	cursor:pointer;
	width:20px;
	vertical-align:top;
}
.block .header .actions i:hover, .widget-block .header .actions i:hover{
	color:#858585;
}

.widget-block .fb-head{
	background:#1784c7;
	border:0;
	color:#FFF;
}

.widget-block .fb-head i{
	display: inline-block;
    text-align: center;
    width: 28px;
}

.bg-darkgray{background-color:#888e97;color:#fff;}
.bg-blue{background-color:#43A0F2;color:#fff;}
.bg-blue .caret, .bg-darkgray .caret{border-color:#fff transparent; border-width: 5px 5px 0;}
.bg-blue a, .bg-darkgray a{color:#FFF;}

.cl-mcont .block .content{
	padding:20px;
}

.cl-mcont .block .stat-data, .cl-mcont .block-flat .stat-data{
	padding:10px;
	float:left;
	width:50%;
}

.cl-mcont .block .stat-data h2, .cl-mcont .block-flat .stat-data h2{margin:0 0 5px 0;}
.cl-mcont .block .stat-data span, .cl-mcont .block-flat .stat-data span{font-size:18px;}

.cl-mcont .block .stat-data .stat-blue, .cl-mcont .block-flat .stat-data .stat-blue{
	background:#43A0F2;
	border-radius:3px;
	-webkit-border-radius:3px;
	text-align:center;
	color:#FFF;
	padding:20px 10px;
}

.cl-mcont .block .stat-data .stat-number, .cl-mcont .block-flat .stat-data .stat-number{border-bottom:1px solid #dadada;}
.cl-mcont .block .stat-data .stat-number:last-child, .cl-mcont .block-flat .stat-data .stat-number:last-child{border-bottom:0;}
.cl-mcont .block .stat-data .stat-number div, .cl-mcont .block-flat .stat-data .stat-number div{display:inline-block;vertical-align:middle;padding:5px 5px;font-size:16px;line-height:20px;}
.cl-mcont .block .stat-data .stat-number h2, .cl-mcont .block-flat .stat-data .stat-number h2{margin:0;font-size:35px;color:#444;font-weight:300;}
.cl-mcont .block .stat-data .stat-number span, .cl-mcont .block-flat .stat-data .stat-number span{font-size:12px;}

 .block .fact-data,  .widget-block .fact-data, .block-flat .fact-data {
	margin:25px 0;
	padding:0 20px;
	display:inline-block;
	width:49%;
	vertical-align: top;
 }
 
 .block .total-data,  .widget-block .total-data, .block-flat .total-data{
	padding:15px;
	display:inline-block;
	width:100%;
	vertical-align: top;
	position:relative;
 }
 .block .dropdown-menu,  .widget-block .dropdown-menu , .block-flat .dropdown-menu{margin:-1px 0 0 0;border-radius:1px;-webkit-border-radius:1px;}
 .btn-group .dropdown-menu{margin-top:-6px;}
 .btn-group{margin-right:5px;margin-left:4px;}
 .static-mn,  .widget-block .static-mn{display:block;position:static;}
 .static-mn,  .widget-block .static-mn{display:block;position:static;}
 .static-lg-menu .static-mn,  .widget-block .static-lg-menu .static-mn{min-width:90%;}
 
.block .items,  .widget-block .items{
	margin:0;
	padding:0;
}

.block .items li,  .widget-block .items li{
	border-top:1px solid #e8e8e8;
	list-style: none;
	font-size: 14px;
    line-height: 17px;
	padding: 13px;
	background-color:#FFF;
   transition: background .25s ease-in-out;
   -moz-transition: background .25s ease-in-out;
   -webkit-transition: background .25s ease-in-out;
}

.block .items li i,  .widget-block .items li i {
    color: #555555;
    display: block;
    float: left;
    font-size: 28px;
    height: 38px;
    margin-left: 4px;
    margin-right: 15px;
    margin-top: 2px;
}

.block .items li small,  .widget-block .items li small{display:block;}

.block .items li:hover,  .widget-block .items li:hover{
	background-color:#F4F4F4;

}

.block .items li .value,  .widget-block .items li .value{
    font-size: 15px;
    margin-top: 7px;
}

.block .items li:first-child,  .widget-block .items li:first-child{border-top:0;}
  .block .total-data h2,  .widget-block .total-data h2{
	margin:0;
	font-size:24px;
	font-weight:300;
	line-height:32px;
  }
  .block .total-data h3,  .widget-block .total-data h3{font-size:18px; font-weight:300;margin:0;}
  .block .total-data h4,  .widget-block .total-data h4{font-size:14px; font-weight:300;margin:0;}
	
  .block .total-data h2 span,  .widget-block .total-data h2 span {
	
  }
   
 .widget-block .fact-data{margin: 27px 0;}
 
  .block .fact-data:first-child{border-right:1px solid #eaeaea;}
 .block .fact-data h2, .widget-block .fact-data h2{
	margin:0;
	font-size: 32px;
    font-weight: 300;
}
  .block .fact-data h3, .widget-block .fact-data h3{
	text-transform:uppercase;
	margin:0;
	font-weight:200;
	font-size:13px;
  }
  
.widget-block .fact-data h3{margin-bottom:13px;font-size:17px;}
.widget-block .fact-data h2{margin-bottom:4px;}
.widget-block .fact-data p{font-size:12px;}
.widget-block .no-padding{padding:0;}

.widget-block .text-shadow h3, .widget-block .text-shadow h2, .widget-block .text-shadow p{}
.widget-block h3{
	font-size: 17px;
	margin:5px 0 0 0;
	font-weight: 200;
}

.widget-block h2{
	font-size:32px;
	font-weight: 300;
	
}

  .block h2 i{
	font-size:24px;
	color:#555;
	margin-right:6px;
  }
  
/*Widget Notes*/
 .cl-mcont .block.widget-notes{
   border-bottom: 1px solid #E2E2E2;
    border-left: 1px solid #EFEFEF;
    border-right: 1px solid #EFEFEF;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
    border-radius:3px;
  }
  
  .cl-mcont .block.widget-notes .header{
    border-bottom:0;
  }
  
  .cl-mcont .block.widget-notes .header h4{
    margin:0;
    font-size:15px;
  }
  
 .cl-mcont .block.widget-notes .content{
  padding: 0;
 }
 
 .paper {
    line-height: 30px;
    font-size: 14px;
    padding: 30px 55px 27px;
    position: relative;
    outline: none;
    background: white;
    background: -webkit-linear-gradient(top, #DFE8EC 0%, white 8%) 0 0px;
    background: -moz-linear-gradient(top, #DFE8EC 0%, white 8%) 0 0px;
    background: linear-gradient(center top, #DFE8EC 0%, white 8%) 0 0px;
    -webkit-background-size: 100% 30px;
    -moz-background-size: 100% 30px;
    -ms-background-size: 100% 30px;
    background-size: 100% 30px;
}
.paper::after {content:''; position:absolute; width:0px; top:0; left:39px; bottom:0; border-left:1px solid #F8D3D3;}
  
  
/*Pie Widget*/
.pie-widget{
  margin-bottom:20px;
}

.pie-widget .stats{
  padding-top:20px;
  border-top:1px solid #DADADA;
}

.pie-widget .stats p{
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.pie-widget .stats h5{
  margin: 0;
}

@media (max-width: 767px) {
  .pie-widget .stats > div:first-child{margin-bottom:20px;}
}