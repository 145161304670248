.cl-mcont .row {
	margin-top:20px;
}

.cl-mcont .col-lg-6{
	padding:0 15px;
}

.cl-mcont .block{
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
	border:0;
	margin-bottom:30px;
	background:#FFF;
	border-radius:3px;
	-webkit-border-radius:3px;
}

.block-flat, .block-wizard{
	margin-bottom:40px;
	padding:20px 20px;
	background:#FFF;
	border-radius:3px;
	-webkit-border-radius:3px;
	border-left:1px solid #efefef;
	border-right:1px solid #efefef;
	border-bottom:1px solid #e2e2e2;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.block-transparent{margin-bottom: 40px;}
.block-transparent .header{
  padding: 0 5px;
  border-bottom:1px solid #E1E1E1;
  margin-bottom:15px;
}


.block-transparent .header h4{
  font-weight:400;
}

.block-transparent .header h4 .fa{ font-size:14px; }