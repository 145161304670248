@import 'fonts/open-sans/css/open-sans.css';
@import 'fonts/font-awesome-4/css/font-awesome.min.css';

@import 'cleanzone.scss';
@import 'variables.scss';
@import 'form.scss';
@import 'card.scss';
@import 'middle-block.scss';

@import 'common.scss';

@import '../components/header.scss';
@import '../components/items.scss';
@import '../components/language-switcher.scss';
@import '../components/logged-out-layout.scss';
@import '../components/items-user-page.scss';
@import '../components/selection-bar.scss';

@import '../components/schedule/index.scss';
@import '../components/schedule/item-counter.scss';
@import '../components/schedule/time/index.scss';

@import 'custom.scss';
@import 'typography.scss';


//Override use of Raleway
.block-flat .header h3 {
  font-family: inherit;
  font-weight: 300;
  font-size: 20px;
}

//Prevent excess margins when using middle-block
.middle-block {
  @media (max-width: 767px) {
    .card, .block-flat {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .card, .block-flat {
      margin: 20px 0;
    }
  }
}


//App-specific template overrides etc.
//Page backgrounds (for login, signup etc.)
.photo-background {
  // background: url(backgrounds/bg19.jpg) no-repeat center;
  background-size: cover;
}


#app, .logged-out {
  height: 100%;
}


#app {
  font-weight: normal;
}

.btn-group {
  flex: 1; // fix for the delete dropdown in item-editor modal footer
}
