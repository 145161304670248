/**
 * Centers content vertically and horizontally on desktop
 * On mobile, takes up full screen
 *
 * NOTE: Parent elements must be 100% height
 */
@media (max-width: 767px) {
  .middle-block {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .middle-block {
    text-align: center;
    height: 100%;
  }

  .middle-block:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .middle-block__content {
    display: inline-block;
    vertical-align: middle;
    width: 767px;
    text-align: left;
  }
}