
.spacer {margin-top: 15px !important;}
.spacer2 {margin-top: 30px !important;}
.spacer3 {margin-top: 50px !important;}

.spacer-bottom-xs {margin-bottom: 10px !important;}
.spacer-bottom {margin-bottom: 30px !important;}
.spacer-bottom-sm {margin-bottom: 15px !important;}
.spacer-bottom-lg {margin-bottom: 40px !important;}

.rad-1{border-radius:1px;-webkit-border-radius:1px;overflow:hidden;}
.rad-2{border-radius:2px;-webkit-border-radius:2px;overflow:hidden;}
.rad-3{border-radius:3px;-webkit-border-radius:3px;overflow:hidden;}

.dash-cols .block .no-padding{padding:0;}
.no-padding{padding:0 !important;}

.clear{clear:both;}
