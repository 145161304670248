/*Buttons*/
.btn{
  border-color: #CCCCCC;
	border-radius:0;
-webkit-border-radius:0;
	outline:none;
	margin-bottom: 5px !important;
	margin-left:4px;
	font-size:13px;
	padding:7px 11px;
	box-shadow:1px 1px 2px rgba(0, 0, 0, 0.12), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}

.btn-default, .btn-default:focus {background-color: #FFFFFF;border-color: #CCCCCC;color: #333333; background-image:linear-gradient(to bottom, #FFFFFF 60%, #F9F9F9 100%);}
.btn-default i{color:#444;}

.btn-primary, .btn-primary:focus {
	background-color: #4D90FD;
	border-color:#3680BF;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {background-color: #4e9dff;border-color:#357ebd;}
.btn-primary:active{box-shadow:0 3px 5px rgba(0, 0, 0, 0.125) inset;}
 
.btn-success, .btn-success:focus { 
	background-color: #60C060;
	border-color: #54A754;
}
.btn-success:hover, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success{ background-color: #65ca65; border-color: #54A754;}

.btn-info, .btn-info:focus {
    background-color: #5BC0DE;
    border-color: #28a1c4;
}
.btn-info:hover, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {background-color:#5fc8e7;border-color:#28A1C4;}

.btn-warning, .btn-warning:focus { 
	background-color: #FF9900; 
	border-color: #E38800;
}
.btn-warning:hover, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning{ background-color: #ffa800;}

.btn-danger, .btn-danger:focus { background-color: #DF4B33; border-color: #CA452E;}
.btn-danger:hover, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger{ background-color: #e64d35;}

.btn-rad{border-radius:2px;-webkit-border-radius:2px;}

.btn i{font-size:14px;margin-right: 2px;display:inline-block; min-width: 10px;}
.btn-group-vertical{margin: 3px 3px;}
/*Specific icons size*/

.btn-lg{padding:12px 14px;  font-size: 15px; font-weight: 300;}
.btn-lg i{font-size:18px;}

.btn-sm{padding:4px 7px;font-size:12px;}
.btn-sm i{font-size:14px;}

.btn-xs{padding:2px 6px;font-size: 11px;}
.btn-xs i{font-size:12px;}

.btn-shadow{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.12);}
.btn-shadow.btn-success{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-primary{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-warning{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-danger{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}
.btn-shadow.btn-info{box-shadow:1px 1px 2px rgba(0, 0, 0, 0.2);}

.btn-flat, .btn-flat:focus{
	box-shadow:none;
}

.btn-flat.btn-default{background: #fff;}
.btn-flat:active.btn-default, .btn-flat.btn-default.active, .btn-flat.open .dropdown-toggle.btn-default{background: #f5f5f5;}
.btn-flat:active.btn-primary, .btn-flat.btn-primary.active, .btn-flat.open .dropdown-toggle.btn-primary{background: #4785ea;}
.btn-flat:active.btn-success, .btn-flat.btn-success.active, .btn-flat.open .dropdown-toggle.btn-success{background: #5db95d;}
.btn-flat:active.btn-info, .btn-flat.btn-info.active, .btn-flat.open .dropdown-toggle.btn-info{background: #56b7d3;}
.btn-flat:active.btn-warning, .btn-flat.btn-warning.active, .btn-flat.open .dropdown-toggle.btn-warning{background: #ff9600;}
.btn-flat:active.btn-danger, .btn-flat.btn-danger.active, .btn-flat.open .dropdown-toggle.btn-danger{background: #d04630;}

.btn-group-vertical > .btn{
	margin-bottom:0 !important;
}

.btn-group-vertical > .btn:first-child{margin-left:0;border-radius:0 !important;-webkit-border-radius:0 !important;}
.btn-group-vertical > .btn:last-child{border-radius:0 !important;-webkit-border-radius:0 !important;}

/*Social buttons*/
.btn-facebook i{color:#3b5998;}
.btn-twitter i{color:#00aced;}
.btn-google-plus i{color:#dd4b39;}
.btn-dribbble i{color:#ea4c89;}
.btn-linkedin i{color:#0e76a8;}
.btn-youtube i{color:#c4302b;}
.btn-pinterest i{color:#c8232c;}
.btn-tumblr i{color:#34526f;}
.btn-flickr i{color:#ff0084;}

.btn-facebook.bg {background:#3b5998;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-twitter.bg {background:#00aced;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-google-plus.bg {background:#dd4b39;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-dribbble.bg {background:#ea4c89;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-linkedin.bg {background:#0e76a8;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-youtube.bg {background:#c4302b;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-pinterest.bg {background:#c8232c;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-tumblr.bg{background:#34526f;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-flickr.bg{background:#0063dc;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}
.btn-github.bg{background:#333;color:#fff;border:1px solid rgba(0, 0, 0, 0.08);}

.btn.bg i{color:#FFF;}

.btn:focus{outline:none;}

.btn-group.btn-blurbg{
	// background: url("../images/blur_bg.png") no-repeat;
	padding:40px;
	margin-bottom:20px;
	box-shadow:1px 1px 2px rgba(0, 0, 0, 0.15);
}

.btn.btn-transparent{
	border-top: 1px solid #fff;
	border-bottom: 1px solid rgba(255,255,255,0.5);
	border-left: 1px solid rgba(255,255,255,0.4);
	border-right: 1px solid rgba(255,255,255,0.4);
	background: rgba(255,255,255,0.2);
	color:#fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
}

.btn.btn-transparent:hover{
	background: rgba(255,255,255,0.32);
}

.video-player{
	position:relative;
	display:inline-block;
	box-shadow:1px 1px 2px rgba(0, 0, 0, 0.15);
}

.video-player video{display:block;max-width:320px;width:100%;}

.video-player .btn-controls{
	text-align:center;
	bottom:5px;
	position:absolute;
	width:100%;
}

.video-player .btn-controls .btn-group{
	margin:0;
}

.video-player .btn-controls .btn-group{float:none;}
button::-moz-focus-inner {
  border: 0;
}

.btn-group .dropdown-menu{
  border-radius:1px;
}

.input-group-btn .btn[type="submit"] {
    padding-bottom: 7px 10px;
}

.btn-link{
  border:0;
  -webkit-box-shadow:none;
  -moz-box-shadow:none;
  box-shadow:none;
}

/*Bootstrap 3.1.0 Fix*/
.btn-group .dropdown-backdrop + .btn {
    margin-left: -1px;
}

.input-group-addon.btn-primary,
.input-group-addon.btn-success,
.input-group-addon.btn-danger,
.input-group-addon.btn-warning
{
  color: #fff;
}