.next-visit-desc * {
  margin-bottom: 0;
  white-space: pre-wrap;
}

.next-visit-desc strong {
  font-weight: 600;
}

.sg-alert .btn {
  margin-bottom: initial !important;
}