
html{height:100%;}

body {
  position:relative;
	padding: 0;
	background-color:#F6F6F6;
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	color:#555;
  //opacity:0;
  margin-left:-20px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Open Sans', sans-serif;
	font-weight:300;
}

a {
    color: #3380FF;
    text-decoration: none;
	outline: 0 none;
}

a:focus, a:hover, a:active {
  outline: 0 none;
	text-decoration: none;
	color:#3078ef;
}

p {
    font-size: 13px;
	line-height: 22px;
}

 b {
    font-weight: 400;
}

blockquote p {
	font-size:15px;
}

ul{padding-left:30px;}


.overflow-hidden{
	overflow:hidden;
}

.no-margin{margin:0 !important;}
.no-margin-y{margin-top:0 !important;margin-bottom:0 !important;}
.no-margin-x{margin-left:0 !important;margin-right:0 !important;}
.no-margin-top{margin-top:0 !important;}
.no-margin-bottom{margin-bottom:0 !important;}
.no-margin-left{margin-left:0 !important;}
.no-margin-right{margin-right:0 !important;}

.big-text{
  font-size:61px;line-height: 70px !important;
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2);
	padding-bottom:5px;
}

.jumbotron {
margin-top: 20px;
}

#pcont{
	margin-left: 216px;
	position:relative;
  padding: 0;
}

.back-to-top {
    position: fixed;
    bottom: 0;
    right: 0px;
    text-decoration: none;
    color: #FFF;
    background-color: rgba(39,41,48,0.8);
    font-size: 12px;
    padding: 9px 12px;
    display: none;
}


.back-to-top i{
  font-size:15px;
}

.back-to-top:hover {   
    color: #FFF; 
    background-color: rgba(39,41,48,1);
}

.back-to-top:focus{
  color:#FFF;
}
