.hthin{
	font-family: "Raleway",Helvetica,sans-serif !important;
	font-weight:100 !important;
}

/*Pre & Code*/
pre {border-radius:2px;-webkit-border-radius:2px;margin-top:10px;}

/*CONTENT*/
.cont h2{
   font-family: "Raleway",Helvetica,sans-serif;
    font-size: 32px;
    font-weight: 100;
    line-height: 38px;
    margin: 0 0 10px 0;
}

.cont h5{
	font-size: 13px;
    font-weight: 200;
    line-height: 21px;
}

.cont p{
	font-size:13px;
	color: #333;
    font-size: 13px;
    font-weight: 200;
    line-height: 23px;
}

.cont b{font-weight: 400;}

/*Well*/
.well p:last-child{margin:0;}

/*Text Colors*/
.color-primary{color:#3078EF;}
.color-primary.fa{color:#4C94FF;}
.color-success{color:#5FBF5F;}
.color-warning{color:#FC9700;}
.color-danger{color:#ee5037;}
